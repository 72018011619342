<template>
    <div class="tw-flex tw-justify-center tw-items-center tw-mt-8">
        <div class="tw-border tw-px-4 tw-py-3 tw-rounded tw-relative tw-inline-flex tw-items-center" :class="classes" role="alert">
            <div aria-label="Loading..." role="status" class="tw-mr-4 tw-self-center" v-if="loading">
                <svg class="tw-h-6 tw-w-6 tw-animate-spin icon" viewBox="0 0 256 256">
                    <line x1="128" y1="32" x2="128" y2="64" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                    <line x1="195.9" y1="60.1" x2="173.3" y2="82.7" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                    <line x1="224" y1="128" x2="192" y2="128" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                    <line x1="195.9" y1="195.9" x2="173.3" y2="173.3" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                    <line x1="128" y1="224" x2="128" y2="192" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                    <line x1="60.1" y1="195.9" x2="82.7" y2="173.3" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                    <line x1="32" y1="128" x2="64" y2="128" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                    <line x1="60.1" y1="60.1" x2="82.7" y2="82.7" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                </svg>
            </div>
            <font-awesome-icon icon="exclamation-circle" size="2x" class="tw-mr-4 tw-self-center" v-else> </font-awesome-icon>

            <span class="tw-block sm:tw-inline">{{ message }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "server-error-message",
    components: {},
    props: {
        message: {
            type: String,
            default: "You are not authorized to perform this operation",
        },
        classes: {
            type: String,
            default: "tw-bg-red-100 tw-border-red-400 tw-text-red-700",
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.icon {
    stroke: #6b7280;
}
</style>
